import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import MyComponent from './MyComponent';
import { useTranslation } from 'react-i18next';
import productDataEn from '../mockApi/products_en.json';
import productDataDe from '../mockApi/products_de.json';
import productDataHr from '../mockApi/products_hr.json';

const WindowCard = ({selectedCategory}) => {
    const { i18n } = useTranslation();
    const [filteredData, setFilteredData] = useState([]);
    const settings2 = {
        arrows: true,
        infinite: false,
        prevArrow: <button type="button" className="slick-prev"><span className="material-icons">chevron_left</span></button>,
        nextArrow: <button type="button" className="slick-next"><span className="material-icons">chevron_right</span></button>,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }
        ]
    };

    useEffect(() => {
        if (i18n.language === "en") {
            setFilteredData(productDataEn)
          } else if (i18n.language === "de") {
            setFilteredData(productDataDe)
          } else if (i18n.language === "hr") {
            setFilteredData(productDataHr)
          }
    }, [i18n.language]);
    return (
        <>
            <div className="container mt-5 ">
                <div className="row justify-content-center">
            
                    <div className=" col-12 px-0 window-Card font-inter ">
                        <Slider {...settings2}>
                        {filteredData.map((category, index) => (
                            <MyComponent
                            key={index}
                            image={`/images/${category.imageFolderName}/`+category?.coverImage}
                            categoryName={category.categorynamelong}
                            slug={category?.categorynameshort}
                            selectedCategory={selectedCategory}
                        />
                            

                        ))}
                         
                        </Slider>
                    </div>
                </div>
            </div>

        </>
    );
}

export default WindowCard;
